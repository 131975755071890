export default {
  "header-cell": "cpE",
  "empty": "cpd",
  "header-content": "cpI caption-bold",
  "active": "cpt",
  "align-right": "cpM",
  "col-8": "cpP",
  "col-7": "cpl",
  "col-5": "cpX",
  "col-4": "cpC"
};
