export default {
  "attachment-viewer": "cvB",
  "hide-sidebar": "cvE",
  "sidebar": "cvd",
  "header": "cvI",
  "header-top": "cvt",
  "header-icon": "cvM",
  "header-amount": "cvP",
  "infobox": "cvl",
  "vat": "cvX",
  "validation": "cvC"
};
