export default {
  "row": "cps",
  "cell": "cpF",
  "greyed-out": "cpg",
  "struck-through": "cpD",
  "flex-cell": "cpV",
  "details-container": "cpL",
  "overdue": "cpT",
  "align-right": "cpz",
  "customer-name-container": "cpa",
  "icon": "cpH",
  "warning": "cpO",
  "with-separator": "cpm",
  "align-center": "cpp"
};
