export default {
  "mileage-validation": "cNM",
  "actions": "cNP",
  "action-btn": "cNl",
  "action-btn-success": "cNX",
  "action-btn-right": "cNC",
  "request-mileage-validation": "cNk",
  "account-selector": "cNJ",
  "has-error": "cNs",
  "funds-disclaimer": "cNF",
  "account-options": "cNg",
  "account-balance": "cND",
  "warnings": "cNV"
};
