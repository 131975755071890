export default {
  "mileage-validation": "coj",
  "actions": "coW",
  "action-btn": "cou",
  "action-btn-right": "cnS",
  "request-mileage-validation": "cnc",
  "account-selector": "cnq",
  "has-error": "cnZ",
  "funds-disclaimer": "cnR",
  "account-options": "cnQ",
  "account-balance": "cne",
  "warnings": "cnB"
};
