export default {
  "container": "cOi",
  "main": "cOA",
  "preview": "cOY",
  "form": "cOh",
  "scrollable": "cOf",
  "header": "cOK title-1",
  "form-sections": "cOG",
  "form-section": "cOr",
  "footer": "cOb",
  "preview-header": "cOy",
  "centered": "cOU",
  "preview-content-wrapper": "cOj",
  "preview-content": "cOW",
  "payment-link-preview": "cOu"
};
