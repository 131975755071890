export default {
  "container": "caC",
  "title-and-trash": "cak",
  "trash": "caJ",
  "quantity-amount": "cas",
  "flex-row": "caF",
  "quantity": "cag",
  "quantityInput": "caD",
  "border-left": "caV",
  "dropdown": "caL",
  "item-row": "caT",
  "unit-price": "caz",
  "price-input": "caa",
  "hidden": "caH",
  "d-none": "caO",
  "dropdown-option": "cam",
  "list-row": "cap",
  "row-title": "cax",
  "row-price": "caw body-2",
  "row-missing-details-container": "caN",
  "row-missing-details-label": "cav body-2",
  "warning-icon": "cao",
  "row-internal-note": "can",
  "with-internal-note": "cai",
  "dropdown-content": "caA",
  "select-item": "caY"
};
