export default {
  "card": "cTY",
  "error": "cTh",
  "customer-info": "cTf",
  "edit-customer-tooltip": "cTK",
  "title": "cTG",
  "flex-row": "cTr",
  "dot-separator": "cTb",
  "flex-span": "cTy",
  "faq-link": "cTU",
  "tooltip-wrapper": "cTj",
  "badge-union": "cTW"
};
