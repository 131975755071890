export default {
  "header": "cYa",
  "header-type": "cYH",
  "close": "cYO",
  "close-icon": "cYm",
  "header-infos": "cYp",
  "title": "cYx body-1",
  "declined": "cYw",
  "canceled": "cYN",
  "approved": "cYv",
  "pending": "cYo",
  "date": "cYn body-2",
  "container": "cYi",
  "picto": "cYA",
  "picto-status": "cYY",
  "general": "cYh",
  "amount": "cYf",
  "counterparty": "cYK body-2",
  "initiator": "cYG",
  "initiator-avatar": "cYr",
  "avatar-image": "cYb",
  "initiator-id": "cYy",
  "name": "cYU body-2",
  "role": "cYj caption-bold",
  "infobox": "cYW",
  "account-infobox": "cYu cYW",
  "beneficiary-warning": "chS caption-bold",
  "dates-wrapper": "chc",
  "dates": "chq",
  "recurrence": "chZ caption-bold",
  "arrow": "chR",
  "notify-checkbox": "chQ"
};
