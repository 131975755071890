export default {
  "header": "chp",
  "header-type": "chx",
  "close": "chw",
  "close-icon": "chN",
  "header-infos": "chv",
  "title": "cho body-1",
  "declined": "chn",
  "canceled": "chi",
  "approved": "chA",
  "pending": "chY",
  "date": "chh body-2",
  "mileage-icon": "chf",
  "distance-subtitle": "chK body-2",
  "link-icon": "chG",
  "container": "chr",
  "picto": "chb",
  "general": "chy",
  "amount": "chU",
  "calculation-container": "chj",
  "counterparty": "chW body-2",
  "infobox": "chu"
};
