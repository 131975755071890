export default {
  "form": "cmS",
  "container": "cmc",
  "section": "cmq",
  "footer": "cmZ",
  "attachment": "cmR",
  "attachment-title": "cmQ",
  "attachment-uploader-disabled": "cme",
  "attachment-overlay": "cmB",
  "attachment-overlay-hidden": "cmE"
};
