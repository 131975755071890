export default {
  "cell-content": "cAg",
  "icon": "cAD",
  "status_check-m": "cAV",
  "description": "cAL body-1",
  "from": "cAT body-2",
  "from--abort": "cAz",
  "description--abort": "cAa",
  "title": "cAH body-2"
};
