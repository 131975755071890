export default {
  "wrapper": "cxL",
  "tabs": "cxT",
  "tabs-nav": "cxz",
  "tabs-panel-wrapper": "cxa",
  "list-head": "cxH",
  "list": "cxO",
  "footer": "cxm",
  "reward-amount": "cxp",
  "earned": "cxx",
  "pending": "cxw",
  "empty-state-wrapper": "cxN",
  "empty-state": "cxv",
  "illustration": "cxo",
  "error-wrapper": "cxn",
  "error-illustration": "cxi",
  "error-description": "cxA",
  "spinner": "cxY",
  "total-earned": "cxh"
};
