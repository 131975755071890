export default {
  "request-transfer-validation": "cbM",
  "account-selector": "cbP",
  "has-error": "cbl",
  "actions": "cbX",
  "action-btn": "cbC",
  "warnings": "cbk",
  "top-border": "cbJ",
  "account-selector-subtitle": "cbs",
  "action-btn-right": "cbF",
  "funds-disclaimer": "cbg",
  "account-options": "cbD",
  "account-balance": "cbV",
  "request-multi-transfer-validation": "cbL",
  "wrapper": "cbT",
  "warning-message-row": "cbz",
  "multi-transfer-align": "cba",
  "button-approve": "cbH",
  "button-approve-spinner": "cbO",
  "button-decline": "cbm"
};
