export default {
  "header": "cwi",
  "header-type": "cwA",
  "close": "cwY",
  "close-icon": "cwh",
  "header-infos": "cwf",
  "title": "cwK body-1",
  "declined": "cwG",
  "canceled": "cwr",
  "approved": "cwb",
  "pending": "cwy",
  "date": "cwU body-2",
  "mileage-icon": "cwj",
  "distance-subtitle": "cwW body-2",
  "link-icon": "cwu",
  "container": "cNS",
  "picto": "cNc",
  "general": "cNq",
  "amount": "cNZ",
  "calculation-container": "cNR",
  "counterparty": "cNQ body-2",
  "infobox": "cNe"
};
