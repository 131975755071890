export default {
  "row": "cAO",
  "empty": "cAm",
  "active": "cAp",
  "animated": "cAx",
  "fadein-item": "cAw",
  "cell": "cAN body-2",
  "cell-content": "cAv",
  "cell-amount": "cAo",
  "cell-content-amount": "cAn body-1"
};
