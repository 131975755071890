export default {
  "selector": "czr",
  "pension-selector-content": "czb",
  "withholding-tax-selector-content": "czy",
  "withholding-tax-selector-content-row": "czU",
  "power-select-field": "czj",
  "rate-field": "czW",
  "stamp-revenue-field": "czu",
  "dropdown-option": "caS",
  "dropdown-content": "cac"
};
