export default {
  "request-expense-report-validation": "chB",
  "account-selector": "chE",
  "has-error": "chd",
  "actions": "chI",
  "action-btn": "cht",
  "action-btn-success": "chM",
  "warnings": "chP",
  "top-border": "chl",
  "account-selector-subtitle": "chX",
  "action-btn-right": "chC",
  "funds-disclaimer": "chk",
  "account-options": "chJ",
  "account-balance": "chs"
};
