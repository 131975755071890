export default {
  "header": "coV",
  "header-type": "coL",
  "close": "coT",
  "close-icon": "coz",
  "header-infos": "coa",
  "title": "coH body-1",
  "declined": "coO",
  "canceled": "com",
  "approved": "cop",
  "pending": "cox",
  "date": "cow body-2",
  "mileage-icon": "coN",
  "distance-subtitle": "cov body-2",
  "link-icon": "coo",
  "container": "con",
  "picto": "coi",
  "general": "coA",
  "amount": "coY",
  "calculation-container": "coh",
  "counterparty": "cof body-2",
  "infobox": "coK"
};
