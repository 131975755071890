export default {
  "credit-note-sidebar": "cTl",
  "body": "cTX",
  "box": "cTC",
  "box-header": "cTk",
  "footer": "cTJ",
  "footer-button": "cTs",
  "sidebar-box": "cTF",
  "border-top": "cTg",
  "row": "cTD",
  "related-invoice-title": "cTV caption-bold",
  "einvoice-rejected": "cTL",
  "related-documents": "cTT"
};
