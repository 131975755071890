export default {
  "section-container": "cwa",
  "disclaimer-section": "cwH",
  "trip-title": "cwO caption-bold mb-16",
  "google-link-container": "cwm",
  "link-icon": "cwp",
  "trip-details": "cwx body-2",
  "trip-details-icon": "cww",
  "trip-details-title": "cwN",
  "subtitle": "cwv caption-bold mb-16",
  "disclaimer": "cwo",
  "visualizer": "cwn"
};
