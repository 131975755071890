export default {
  "client-form": "cLj",
  "align-start": "cLW",
  "kind": "cLu",
  "name-fields": "cTS",
  "field": "cTc",
  "required-data-disclaimer": "cTq",
  "row": "cTZ",
  "child": "cTR",
  "confirm-action": "cTQ",
  "delete-action": "cTe",
  "legal-info": "cTB",
  "column-layout": "cTE"
};
