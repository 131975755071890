export default {
  "header": "cfg",
  "header-type": "cfD",
  "close": "cfV",
  "close-icon": "cfL",
  "header-infos": "cfT",
  "title": "cfz body-1",
  "declined": "cfa",
  "canceled": "cfH",
  "approved": "cfO",
  "date": "cfm body-2",
  "container": "cfp",
  "picto": "cfx",
  "request": "cfw",
  "picto-status": "cfN",
  "check": "cfv",
  "stop": "cfo",
  "warning": "cfn",
  "general": "cfi",
  "amount": "cfA",
  "counterparty": "cfY body-2",
  "initiator": "cfh",
  "initiator-avatar": "cff",
  "avatar-image": "cfK",
  "initiator-id": "cfG",
  "name": "cfr body-2",
  "role": "cfb caption-bold",
  "infobox": "cfy",
  "account-infobox": "cfU cfy",
  "beneficiary-warning": "cfj caption-bold",
  "dates-wrapper": "cfW",
  "dates": "cfu",
  "recurrence": "cKS caption-bold",
  "arrow": "cKc",
  "notify-checkbox": "cKq"
};
