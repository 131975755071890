export default {
  "container": "cmA",
  "content": "cmY",
  "input-row": "cmh",
  "email-row": "cmf",
  "button-row": "cmK",
  "btn-edit": "cmG",
  "input-column": "cmr",
  "form-btn-row": "cmb",
  "border-bottom": "cmy",
  "avatar-input": "cmU"
};
