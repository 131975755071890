export default {
  "iban": "crt",
  "checkbox": "crM",
  "toggle-content": "crP",
  "beneficiary-name": "crl",
  "category": "crX",
  "beneficiary-email": "crC",
  "beneficiary-form": "crk",
  "beneficiary-email-container": "crJ"
};
