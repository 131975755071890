export default {
  "header-cell": "cKU",
  "header-content": "cKj caption-bold",
  "active": "cKW",
  "empty": "cKu",
  "request-type": "cGS",
  "status": "cGc",
  "note": "cGq",
  "amount": "cGZ"
};
