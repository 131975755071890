export default {
  "payment-details": "cah",
  "label-payment-conditions": "caf",
  "dropdown-content": "caK",
  "dropdown-option": "caG",
  "activate-sdd-container": "car",
  "activate-sdd-header": "cab",
  "selector-toggle-sdd": "cay",
  "selector-toggle-sdd-subtitle": "caU",
  "selector-toggle-content": "caj",
  "selector-toggle": "caW",
  "new-badge": "cau",
  "new-badge-activation": "cHS",
  "mt-16": "cHc",
  "sdd-mandate-select-loading": "cHq"
};
