export default {
  "sidebar": "cHf",
  "body": "cHK",
  "form": "cHG",
  "form-section": "cHr",
  "form-title": "cHb title2",
  "row": "cHy",
  "payment-details": "cHU",
  "col": "cHj",
  "footer": "cHW",
  "footer-button": "cHu"
};
