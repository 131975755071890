export default {
  "section-container": "chF",
  "disclaimer-section": "chg",
  "trip-title": "chD caption-bold mb-16",
  "google-link-container": "chV",
  "link-icon": "chL",
  "trip-details": "chT body-2",
  "trip-details-icon": "chz",
  "trip-details-title": "cha",
  "subtitle": "chH caption-bold mb-16",
  "disclaimer": "chO",
  "visualizer": "chm"
};
