export default {
  "wrapper": "crv",
  "beneficiary": "cro",
  "name": "crn body-1",
  "beneficiary-email": "cri body-2",
  "dropdown-icon": "crA",
  "trusted": "crY",
  "account-number": "crh flex body-2",
  "account-icon": "crf",
  "m-chip": "crK",
  "dropdown": "crG body-2",
  "delete-option": "crr",
  "beneficiary-name": "crb"
};
