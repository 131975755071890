export default {
  "wrapper": "cru",
  "header": "cbS mb-48",
  "form-title": "cbc title-2 mb-16",
  "form-subtitle": "cbq body-2",
  "form": "cbZ",
  "section": "cbR mb-48",
  "section-title": "cbQ title-3 mb-16",
  "form-footer": "cbe",
  "footer-btn": "cbB",
  "transfer-form": "cbE"
};
