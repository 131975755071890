export default {
  "referral-invite": "cxt",
  "referral": "cxM",
  "illustration": "cxP",
  "steps": "cxl",
  "step": "cxX",
  "share-referral": "cxC",
  "choice": "cxk",
  "line": "cxJ",
  "email": "cxs",
  "email-input": "cxF",
  "input-field--error": "cxg",
  "error-message": "cxD",
  "external-link-icon": "cxV"
};
