export default {
  "item": "cmk",
  "details": "cmJ",
  "flex": "cms",
  "header": "cmF cms",
  "flex-no-gap": "cmg cms",
  "file-name": "cmD",
  "label": "cmV body-2",
  "self-icon": "cmL",
  "with-separator": "cmT",
  "dimmed": "cmz"
};
