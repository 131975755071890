export default {
  "invoice-sidebar": "cOS",
  "body": "cOc",
  "box": "cOq",
  "box-header": "cOZ",
  "footer": "cOR",
  "footer-button": "cOQ",
  "sidebar-box": "cOe",
  "border-top": "cOB",
  "row": "cOE",
  "status-container": "cOd",
  "due-date-container": "cOI",
  "mark-as-btn": "cOt",
  "primary-actions": "cOM cOe",
  "primary-action": "cOP body-1",
  "button-icon": "cOl"
};
