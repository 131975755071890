export default {
  "subtitle": "cil",
  "green-text": "ciX",
  "balance-subtitle": "ciC",
  "options": "cik",
  "shadow": "ciJ",
  "menu-item": "cis body-2",
  "close-account": "ciF",
  "footer": "cig",
  "progress": "ciD",
  "progress-bar": "ciV",
  "progress-bar-text": "ciL",
  "progress-active": "ciT"
};
