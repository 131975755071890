export default {
  "row": "cym",
  "cell": "cyp",
  "checkbox": "cyx",
  "icon": "cyw",
  "creditor-item-container": "cyN",
  "actions-item-container": "cyv",
  "due-date": "cyo",
  "amount": "cyn",
  "align-right": "cyi"
};
