export default {
  "header": "cvV",
  "header-type": "cvL",
  "close": "cvT",
  "close-icon": "cvz",
  "header-infos": "cva",
  "title": "cvH body-1",
  "declined": "cvO",
  "canceled": "cvm",
  "approved": "cvp",
  "pending": "cvx",
  "date": "cvw body-2",
  "container": "cvN",
  "picto": "cvv",
  "picto-status": "cvo",
  "general": "cvn",
  "amount": "cvi",
  "counterparty": "cvA body-2",
  "initiator": "cvY",
  "initiator-avatar": "cvh",
  "avatar-image": "cvf",
  "initiator-id": "cvK",
  "name": "cvG body-2",
  "role": "cvr caption-bold",
  "infobox": "cvb",
  "account-infobox": "cvy cvb",
  "beneficiary-warning": "cvU caption-bold",
  "dates-wrapper": "cvj",
  "dates": "cvW",
  "recurrence": "cvu caption-bold",
  "arrow": "coS",
  "notify-checkbox": "coc"
};
