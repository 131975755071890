export default {
  "sections": "cra",
  "actions": "crH",
  "search-input": "crO",
  "no-upload": "crm",
  "new-link": "crp body-2",
  "between-links": "crx",
  "action-bar": "crw",
  "empty-state": "crN"
};
