export default {
  "qonto-pilot-modal": "cLc",
  "content": "cLq",
  "header": "cLZ",
  "transition-state-container": "cLR",
  "lottie": "cLQ",
  "prompt-input": "cLe",
  "buttons": "cLB",
  "footer": "cLE caption",
  "divider": "cLd",
  "illustration": "cLI",
  "icon": "cLt",
  "quick-actions-container": "cLM",
  "action": "cLP body-2",
  "action-title": "cLl",
  "disabled": "cLX",
  "action-tag": "cLC caption-bold",
  "hotkey": "cLk body-1",
  "footer-action": "cLJ",
  "grow": "cLs",
  "footer-feedback-link": "cLF",
  "buttons-container": "cLg",
  "transfer-details-container": "cLD",
  "error-container": "cLV",
  "option-iban": "cLL",
  "actions": "cLT",
  "title": "cLz",
  "group-title": "cLa caption-bold",
  "beta-badge": "cLH",
  "hotkeys": "cLO",
  "supplier-invoices-dropzone": "cLm",
  "success-icon": "cLp",
  "error-icon": "cLx",
  "file-items": "cLw",
  "file-item": "cLN",
  "uploader-file": "cLv",
  "transactions-table": "cLo",
  "transaction": "cLn",
  "prompt-example": "cLi"
};
