export default {
  "wrapper": "cpb",
  "tabs": "cpy",
  "tabs-nav": "cpU",
  "tabs-panel": "cpj",
  "tabs-panel-wrapper": "cpW",
  "list-head": "cpu",
  "list": "cxS",
  "footer": "cxc",
  "reward-amount": "cxq",
  "rewarded": "cxZ",
  "registered": "cxR",
  "disclaimer": "cxQ",
  "empty-state-wrapper": "cxe",
  "empty-state": "cxB",
  "illustration": "cxE",
  "spinner": "cxd",
  "total-earned": "cxI"
};
