export default {
  "header-cell": "cnI",
  "empty": "cnt",
  "header-content": "cnM caption-bold",
  "active": "cnP",
  "header-type": "cnl",
  "header-reason": "cnX",
  "header-status": "cnC",
  "header-amount": "cnk",
  "row-sidebar": "cnJ",
  "hidden": "cns",
  "animated": "cnF",
  "fadein-item": "cng",
  "animated-cell": "cnD"
};
