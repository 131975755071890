export default {
  "numbering-container": "caq",
  "flex-row": "caZ",
  "automatic-number-label": "caR",
  "icon-tooltip": "caQ",
  "fix-width": "cae",
  "dates-container": "caB",
  "with-performance-date": "caE",
  "date-picker": "cad",
  "label": "caI",
  "header-text-field-container": "cat",
  "mt-16": "caM",
  "header-text-field": "caP",
  "header-text-field-close-button": "cal",
  "deposit-toggle-container": "caX"
};
