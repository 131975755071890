export default {
  "row": "cnV",
  "active": "cnL",
  "dot": "cnT",
  "red": "cnz",
  "green": "cna",
  "yellow": "cnH",
  "status": "cnO",
  "align-right": "cnm",
  "animated": "cnp",
  "fadein-item": "cnx",
  "cell": "cnw body-2",
  "cell-content": "cnN",
  "amount": "cnv body-2",
  "subtitle": "cno caption",
  "padding-left": "cnn",
  "padding-right": "cni",
  "note": "cnA"
};
