export default {
  "modal": "cmd",
  "action-container": "cmI",
  "similar-label": "cmt",
  "info-icon": "cmM",
  "container": "cmP",
  "subtitle": "cml caption-bold",
  "error": "cmX",
  "item-container": "cmC"
};
