export default {
  "section-container": "coM",
  "disclaimer-section": "coP",
  "trip-title": "col caption-bold mb-16",
  "google-link-container": "coX",
  "link-icon": "coC",
  "trip-details": "cok body-2",
  "trip-details-icon": "coJ",
  "trip-details-title": "cos",
  "subtitle": "coF caption-bold mb-16",
  "disclaimer": "cog",
  "visualizer": "coD"
};
