export default {
  "mileage-validation": "cfQ",
  "actions": "cfe",
  "action-btn": "cfB",
  "approve-btn": "cfE",
  "request-mileage-validation": "cfd",
  "account-selector": "cfI",
  "has-error": "cft",
  "funds-disclaimer": "cfM",
  "account-options": "cfP",
  "account-balance": "cfl",
  "warnings": "cfX"
};
