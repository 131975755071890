export default {
  "header-cell": "cKw",
  "header-content": "cKN caption-bold",
  "active": "cKv",
  "empty": "cKo",
  "align-right": "cKn",
  "request-type": "cKi",
  "requester": "cKA",
  "amount": "cKY"
};
