export default {
  "modal-wrapper": "cma",
  "spinner-container": "cmH",
  "spinner": "cmO",
  "container": "cmm",
  "tabs-item": "cmp",
  "form-container": "cmx",
  "fieldset": "cmw",
  "preview-container": "cmN",
  "switch-container": "cmv",
  "pdf-preview-container": "cmo",
  "form-content": "cmn",
  "button-container-footer": "cmi"
};
