export default {
  "attachment-viewer": "cYt",
  "hide-sidebar": "cYM",
  "sidebar": "cYP",
  "header": "cYl",
  "header-top": "cYX",
  "header-icon": "cYC",
  "header-amount": "cYk",
  "infobox": "cYJ",
  "vat": "cYs",
  "validation": "cYF"
};
