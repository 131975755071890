export default {
  "row": "cGs",
  "active": "cGF",
  "animated": "cGg",
  "fadein-item": "cGD",
  "cell": "cGV body-2",
  "cell-content": "cGL",
  "subtitle": "cGT caption",
  "no-padding": "cGz",
  "note": "cGa",
  "cell-amount": "cGH",
  "cell-content-amount": "cGO body-1"
};
