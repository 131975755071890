export default {
  "wrapper": "cAc",
  "request-header": "cAq",
  "request": "cAZ",
  "scheduled": "cAR",
  "scheduled-label": "cAQ",
  "note": "cAe",
  "note-label": "cAB",
  "note-label-danger": "cAE",
  "note-content": "cAd",
  "status": "cAI",
  "transfer-amount": "cAt"
};
