export default {
  "row": "cGm",
  "active": "cGp",
  "dot": "cGx",
  "red": "cGw",
  "green": "cGN",
  "yellow": "cGv",
  "status": "cGo",
  "align-right": "cGn",
  "animated": "cGi",
  "fadein-item": "cGA",
  "cell": "cGY body-2",
  "cell-content": "cGh",
  "amount": "cGf body-2",
  "subtitle": "cGK caption",
  "padding-left": "cGG",
  "padding-right": "cGr",
  "note": "cGb"
};
