export default {
  "row": "cGR",
  "active": "cGQ",
  "dot": "cGe",
  "red": "cGB",
  "green": "cGE",
  "yellow": "cGd",
  "status": "cGI",
  "align-right": "cGt",
  "animated": "cGM",
  "fadein-item": "cGP",
  "cell": "cGl body-2",
  "cell-content": "cGX",
  "amount": "cGC body-1",
  "subtitle": "cGk caption",
  "no-padding": "cGJ"
};
