export default {
  "content": "ciz",
  "balance": "cia",
  "duration": "ciH",
  "green-text": "ciO",
  "balance-subtitle": "cim",
  "balance-duration": "cip",
  "footer": "cix",
  "progress": "ciw",
  "progress-bar": "ciN",
  "progress-bar-text": "civ",
  "progress-active": "cio"
};
