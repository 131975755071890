export default {
  "row": "cNO body-1",
  "active": "cNm",
  "cell": "cNp",
  "empty": "cNx",
  "ellipsis": "cNw",
  "text-secondary": "cNN",
  "transfer-type": "cNv",
  "transfer-type__icon": "cNo",
  "quick-actions": "cNn",
  "amount": "cNi",
  "cell-approve": "cNA",
  "account-select": "cNY",
  "row-compact": "cNh",
  "cell-amount": "cNf",
  "cell-content-receipt": "cNK body-1",
  "cell-date": "cNG",
  "row-sidebar": "cNr",
  "hidden": "cNb",
  "animated": "cNy",
  "fadein-item": "cNU",
  "animated-cell": "cNj",
  "note": "cNW",
  "checkbox": "cNu"
};
