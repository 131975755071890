export default {
  "card-details-container": "cAy",
  "title": "cAU caption-bold mb-16",
  "section": "cAj",
  "divider": "cAW",
  "top-divider": "cAu",
  "content": "cYS body-2",
  "description": "cYc",
  "justify-end": "cYq",
  "icon": "cYZ ml-8"
};
