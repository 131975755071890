export default {
  "container": "cmj",
  "share-panel": "cmW",
  "email-margin": "cmu",
  "message-margin": "cpS",
  "esignature-container": "cpc",
  "esignature-teaser-title": "cpq",
  "esignature-teaser-badge": "cpZ",
  "esignature-teaser-body": "cpR",
  "button-container-footer": "cpQ",
  "attachments": "cpe",
  "attachment-file": "cpB"
};
