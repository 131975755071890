export default {
  "gap-32": "czR",
  "fieldset": "czQ",
  "date-picker": "cze",
  "with-performance-date": "czB",
  "mt-4": "czE",
  "clients": "czd",
  "dropdown-option": "czI",
  "dropdown-content": "czt",
  "select-client": "czM",
  "client-option": "czP",
  "icon-tooltip": "czl",
  "payment-details": "czX",
  "dates-container": "czC",
  "automatic-number-label": "czk",
  "label": "czJ",
  "numbering-container": "czs",
  "fix-width": "czF",
  "customer-error": "czg",
  "amount-text": "czD",
  "header-text-field-container": "czV",
  "header-text-field": "czL",
  "header-text-field-close-button": "czT",
  "mt-16": "czz"
};
