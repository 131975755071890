export default {
  "invoice-sidebar": "cHC",
  "body": "cHk",
  "box": "cHJ",
  "box-header": "cHs",
  "footer": "cHF",
  "footer-button": "cHg",
  "footer-button-tooltip": "cHD",
  "reduced-fontsize": "cHV",
  "sidebar-box": "cHL",
  "border-top": "cHT",
  "row": "cHz",
  "status-container": "cHa",
  "due-date-container": "cHH",
  "greyed-out": "cHO",
  "struck-through": "cHm",
  "overdue": "cHp",
  "primary-actions": "cHx cHL",
  "primary-action": "cHw",
  "danger-action": "cHN",
  "button-icon": "cHv",
  "button-label": "cHo body-2",
  "related-credit-note-title": "cHn caption-bold",
  "paid-at-placeholder": "cHi",
  "collapsible-timeline": "cHA",
  "timeline-status-label": "cHY",
  "related-documents": "cHh"
};
