export default {
  "container": "cOX",
  "form-container": "cOC",
  "content": "cOk",
  "fields": "cOJ",
  "input-settings": "cOs",
  "current-year": "cOF",
  "next-invoice-number": "cOg",
  "preview-container": "cOD",
  "actions": "cOV",
  "close-button": "cOL btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cOT",
  "preview-image": "cOz"
};
