export default {
  "row": "cxj",
  "active": "cxW",
  "animated": "cxu",
  "fadein-item": "cwS",
  "cell": "cwc",
  "cell-content": "cwq",
  "row-sidebar": "cwZ",
  "empty": "cwR",
  "cell-amount": "cwQ",
  "subtitle": "cwe caption",
  "hidden": "cwB",
  "cell-approve": "cwE",
  "quick-actions": "cwd",
  "account-select": "cwI",
  "note": "cwt",
  "cell-content-amount": "cwM body-1",
  "cell-status": "cwP"
};
